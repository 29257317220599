// Colors

// The color palette
$palette: (
  light: (
    1: #eceded,
    2: #9ca9b3,
    3: #717d86,
    4: #01303f,
  ),
  dark: (
    1: #151719,
    2: #25282c,
    3: #33363a,
  ),
  primary: (
    1: #6163ff,
    2: #acadff,
    3: #5658dd,
    4: #e9e9ff,
    5: #b48952,
  ),
  secondary: (
    1: #24e5af,
    2: #73efcc,
    3: #1cb68b,
    4: #d2f9ee,
  ),
  alert: (
    error: #ff6171,
    warning: #ffa173,
    success: #24e5af,
  ),
  easyblue: (
    1: #01303f,
    2: #ff0000,
  ),
  offwhite: (
    1: #fbf8f8,
  ),
);

// Base typography: color
$color: (
  heading: get-color(light, 4),
  base: get-color(light, 2),
  high-contrast: get-color(light, 1),
  mid-contrast: get-color(light, 2),
  low-contrast: get-color(light, 3),
  primary: get-color(primary, 3),
  secondary: get-color(secondary, 1),
  error: get-color(alert, error),
  warning: get-color(alert, warning),
  success: get-color(alert, success),
  easyblue: get-color(easyblue, 1),
  heading-inverse: get-color(dark, 1),
  base-inverse: get-color(light, 3),
  high-contrast-inverse: get-color(dark, 1),
  mid-contrast-inverse: get-color(dark, 2),
  low-contrast-inverse: get-color(light, 3),
);

// Borders and dividers: color
$border--color: (
  divider: get-color(dark, 2),
  divider-inverse: get-color(light, 1),
);

// Icons: fill color
$icon--color: (
  hamburger: get-color(light, 2),
  // hamburger icon
    accordion: get-color(primary, 1),
  // accordion icon
    social: get-color(primary, 1),
  // social icons
    social-hover: get-color(primary, 2),
  // social icons (:hover)
    modal: get-color(light, 3),
  // modal icon
    modal-hover: get-color(light, 2),
  // modal icon (:hover)
    news-more: get-color(primary, 2),
  // news - read more link arrow
    // ↓ Inverted colors
    hamburger-inverse: get-color(dark, 2),
  // hamburger icon
    accordion-inverse: get-color(primary, 1),
  // accordion icon
    social-inverse: null,
  // social icons
    social-hover-inverse: null
    // social icons (:hover),,,,,,,,,,,,,,,,,,,,,,,,,,,
);

// Misc elements: background color
$bg--color: (
  body: get-color(offwhite, 1),
  body-shadow: 0 20px 48px rgba(get-color(dark, 1), 0.8),
  // body shadow when a boxed layout is used (set to null if don't want a shadow)
    body-outer: lighten(get-color(dark, 1), 2%),
  // outer bg color when a boxed layout is used
    bg-color: lighten(get-color(light, 1), 9%),
  // .has-bg-color helper class
    shadow: 0 24px 64px rgba(get-color(dark, 1), 0.64),
  // .has-shadow helper class
    code: darken(get-color(dark, 1), 3%),
  code-inverse: darken(get-color(dark, 1), 3%),
);
